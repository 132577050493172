<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Nieuw wachtwoord instellen" />
    <h1>Nieuw wachtwoord instellen</h1>
    <b-alert v-if="invalidLink || !code" show variant="warning">
      <font-awesome-icon icon="exclamation-circle" />Uw wachtwoord herstel link
      lijkt onjuist of verlopen, uw wachtwoord is niet aangepast. Vraag opnieuw
      een
      <router-link :to="{ name: 'forgotPassword' }"
        >nieuw wachtwoord</router-link
      >aan.
    </b-alert>

    <p v-if="!invalidLink && code">Vul hieronder uw nieuwe wachtwoord in</p>

    <b-row v-if="!invalidLink && code">
      <b-col sm="12" class="mb-3"></b-col>
      <b-col cols="12">
        <hr />
        <b-form-text id="password-help-block">
          <p>Een nieuwe wachtwoord moet:</p>
          <ul>
            <li>- Minimaal 8 karakters zijn.</li>
            <li>- Met minimaal één hoofdletter. A t/m Z.</li>
            <li>- Met minimaal één kleine letter. a t/m z.</li>
            <li>- Met minimaal één cijfer. 0 t/m 9.</li>
            <li>- Met minimaal één speciale teken, keuze uit !@$%&</li>
          </ul>
        </b-form-text>
      </b-col>

      <b-col sm="12" md="6" lg="5">
        <b-card>
          <b-form @submit.prevent="resetPasswordSubmit">
            <b-row>
              <b-col cols="12" class="pb-3">
                <label>
                  Nieuwe wachtwoord
                  <span class="text-danger"></span>
                </label>
                <b-input-group>
                  <b-form-input
                    id="input-password"
                    v-model="form.password"
                    :type="showPassword ? 'text' : 'password'"
                    :state="passwordValidation"
                    placeholder="Wachtwoord"
                    name="password"
                    required
                    min="8"
                    max="32"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      title="Wachtwoord tonen"
                      variant="primary"
                      @click="showPasswordToggle()"
                    >
                      <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                      <font-awesome-icon
                        v-if="showPassword"
                        fas
                        icon="eye-slash"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="12">
                <label>
                  Herhaal wachtwoord
                  <span class="text-danger"></span>
                </label>
                <b-input-group>
                  <b-form-input
                    id="input-passwordConfirm"
                    v-model="form.confirmPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :state="passwordConfirmState && passwordValidation"
                    name="confirmPassword"
                    placeholder="Vul uw wachtwoord nogmaals in"
                    required
                    min="8"
                    max="32"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      title="Wachtwoord tonen"
                      variant="primary"
                      @click="showPasswordToggle()"
                    >
                      <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                      <font-awesome-icon
                        v-if="showPassword"
                        fas
                        icon="eye-slash"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col cols="12">
                <b-button
                  class="mb-2"
                  type="submit"
                  :disabled="!passwordConfirmState || !passwordValidation"
                  variant="primary"
                >
                  Wachtwoord wijzigen
                  <font-awesome-icon
                    v-if="requesting"
                    class="fa-spin"
                    far
                    :icon="['fad', 'spinner']"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { setNewPassword } from '@/services/AuthorizationService'
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      form: {
        password: '',
        confirmPassword: ''
      },
      invalidLink: false,
      success: false,
      requesting: false,
      wrongPassword: false,
      showPassword: false
    }
  },
  computed: {
    code: function() {
      return this.$route.query.code || false
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        var numberRegex = /[0-9]/g
        if (!password.match(numberRegex)) {
          return false
        }

        const lowercaseRegex = /[a-z]/g
        if (!password.match(lowercaseRegex)) {
          return false
        }

        const capitalRegex = /[A-Z]/g
        if (!password.match(capitalRegex)) {
          return false
        }

        const symbolRegex = /[!|@|#|$|%|&]/g
        if (!password.match(symbolRegex)) {
          return false
        }

        return true
      }

      return null
    },

    passwordConfirmState() {
      if (this.form.password != '') {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    }
  },
  watch: {
    form: {
      handler() {
        if (this.form.password != this.confirmPassword) {
          this.wrongPassword = true
        } else {
          this.wrongPassword = false
        }
      },
      deep: true
    }
  },
  methods: {
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    async resetPasswordSubmit() {
      this.requesting = true

      const result = await setNewPassword({
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        code: this.code
      })

      if (
        result.data != null &&
        result.data != 'FAILURE' &&
        result.data == 'SUCCESS'
      ) {
        this.success = true
        this.$router.push({ name: 'login', query: { passwordReset: true } })
      } else {
        this.invalidLink = true
      }

      this.requesting = false
    }
  }
}
</script>
<style scoped lang="scss">
a.btn {
  text-decoration: none;
}
</style>
